
.benefits-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  padding: 0;
  list-style: none;

  &__item {
    width: calc(100% / 3);
    padding: 0 12px;
    padding-bottom: 24px;

    @include media("<1366px") {
      width: 100%;
    }
    @include media('<=860px') {
      width: 100%;
    }

  }
}

.benefit-card {
  background-color: $darkBox;
  font-family: $mainSansSerifBold;
  height: 280px;
  border-radius: 10px;

  position: relative;
  font-size: 15px;
  line-height: 1.3;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  padding-top: 50px;

  @include media("<568px") {
    font-size: 14px;
  }
  &__title {
    margin: 0 auto;
    max-width: 200px;
    text-transform: none;
  }
  &__icon {
    max-width: 130px;
    margin-bottom: 30px;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    img {
      max-width: 100%;
    }
  }
}